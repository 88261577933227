<template>
  <PageContainer
    :title="title"
    :back="headerActions.back"
    :ellipsis="true"
    :action="headerActions.action"
  >
    <template v-slot:content>
      <MedicalEpisode ref="episode" />
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer } from '@seliaco/red-panda'
import MedicalEpisode from './components/MedicalEpisode'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalDetail',
  components: {
    PageContainer,
    MedicalEpisode
  },
  data () {
    const headerActions = {
      action: {
        icon: 'icon-close',
        callback: () => this.headerAction()
      }
    }

    return {
      headerActions
    }
  },
  mounted () {
    this.actionsInit()
  },
  methods: {
    actionsInit () {
      if (this.episode.status === 'SAVED') {
        this.headerActions = {
          back: {
            callback: () => {
              if (this.$route.query.back) {
                this.$router.replace({
                  path: this.$route.query.back
                })
              } else {
                this.$router.go(-1)
              }
            }
          }
        }
      }
    },
    headerAction () {
      this.$refs.episode.hadlerStatusEpisode('DRAFT', true)
    }
  },
  computed: {
    ...mapGetters({
      episode: 'medical/episode',
      appointment: 'medical/appointment'
    }),
    title () {
      return this.$translations['medical-history'].episode.replace(
        '{date}',
        this.$moment(this.episode.created_at).format('DD/MM/YYYY')
      )
    }
  }
}
</script>
